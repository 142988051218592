import React from "react";
import {createMemoryHistory} from "history";
import { Redirect, Route, Router, Switch} from "react-router-dom";
import Helmet from "react-helmet";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

import AboutPage from "./AboutPage/AboutPage.jsx";
import BetaPage from "./BetaPage/BetaPage.jsx";
import BetaProgramConfirmation from "./BetaProgramConfirmation/BetaProgramConfirmation.jsx";
import BetaProgramDetails from "./BetaProgramDetails/BetaProgramDetails.jsx";
import BlogPage from "./BlogPage/BlogPage.jsx";
import BlogSubscribePage from "./BlogSubscribePage/BlogSubscribePage.jsx";
import ComingSoon from "./ComingSoon/ComingSoon.jsx";
import FAQPage from "./FAQPage/FAQPage.jsx";
import FeaturePage from "./FeaturePage/FeaturePage.jsx";
import FreeTrialPage from "./FreeTrialPage/FreeTrialPage.jsx";
import HomePage from "./HomePage/HomePage.jsx";
import LandingPage from "./LandingPage/LandingPage.jsx";
import LoginPage from "./LoginPage/LoginPage.jsx";
import NotFound from "./NotFound/NotFound.jsx";
import ProfilePage from "./ProfilePage/ProfilePage.jsx";
import PurchasePage from "./PurchasePage/PurchasePage.jsx";
import RegisterPage from "./RegisterPage/RegisterPage.jsx";
import ThanksFreeTrial from "./ConversionPages/ThanksFreeTrial.jsx";
import ThanksPurchase from "./ConversionPages/ThanksPurchase.jsx";
import ThanksSubscribe from "./ConversionPages/ThanksSubscribe.jsx";


let hist = createMemoryHistory();

export default () => (
  <div>
    <Helmet>
      <title>BookMerlin</title>
    </Helmet>
  <Router history={hist}>
    <Switch>
      <Route path="/" component={ComingSoon} />
      <Route path="/NotFound" component={NotFound} />
      <Route path="/About" component={AboutPage} />
      <Route path="/Beta" component={BetaPage} />
      <Route path="/BetaProgramConfirmation" component={BetaProgramConfirmation} />
      <Route path="/BetaProgramDetails" component={BetaProgramDetails} />
      <Route path="/Blog" component={BlogPage} />
      <Route path="/BlogSubscribe" component={BlogSubscribePage} />
      <Route path="/Faq" component={FAQPage} />
      <Route path="/Feature" component={FeaturePage} />
      <Route path="/FreeTrial" component={FreeTrialPage} />
      <Route path="/Home" component={HomePage} />
      <Route path="/Landing" component={LandingPage} />
      <Route path="/Login" component={LoginPage} />
      <Route path="/Profile" component={ProfilePage} />
      <Route path="/Purchase" component={PurchasePage} />
      <Route path="/Register" component={RegisterPage} />
      <Route path="/ThanksForFreeTrial" component={ThanksFreeTrial} />
      <Route path="/ThanksForPurchase" component={ThanksPurchase} />
      <Route path="/ThanksForSubscription" component={ThanksSubscribe} />
    </Switch>
  </Router>
  </div>
);
