import React from "react";
import Helmet from "react-helmet";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import BlogShortParallax from "components/ShortParallax/BlogShortParallax.jsx";

import featurePageStyle from "assets/jss/material-kit-react/views/featurePageStyle.jsx";

import WriteHere from "../HomePage/SubSections/WriteHere.jsx"
import EditHere from "../HomePage/SubSections/EditHere.jsx"
import FormatHere from "../HomePage/SubSections/FormatHere.jsx"


const dashboardRoutes = [];

class FeaturePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688" //changed from white
          }}
          {...rest}
        />
        <BlogShortParallax filter image={require("assets/img/background.jpg")} >
          <div className={classes.container}>
            <GridContainer>
              <GridItem >
                <div >
                  <div >
                  <h1 className={classes.mainTitle}>
                        Features
                    </h1>
                    <h4>
                      This will be words to describe how each feature was created with the user in mind.
                    </h4>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </BlogShortParallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Features</h2>
                <h5 className={classes.description}>
                  This will be about the features.
                </h5>

            </GridItem>
          </GridContainer>
        </div>
            <WriteHere />
            <EditHere />
            <FormatHere />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(featurePageStyle)(FeaturePage);
